
/*define fonts */
@font-face{
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face{
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face{
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

@font-face{
  font-family: COD;
  src: url('./assets/font/CallOfDuty.ttf');
  font-weight: 400;
}

/*define CSS */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: rgb(0, 0, 0) !important;
  color:ghostwhite !important;
  font-family: 'Centra', sans-serif !important;
}

/*may need to be  updated to less*/
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*navbar css*/
nav.navbar {
  padding: 10px 0;
  height: 150px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.2s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color:black;
}



nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color:ghostwhite !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active{
  opacity: 1;
}

span.navbar-text{
  display: flex;
  align-items: center;
}

.social-icon{
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
}

.social-icon a::before{
    content:"";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before{
  transform: scale(1);
}

.social-icon a img{
  width: 40%;
  z-index: 999;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}



nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
    box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: white;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}


.collapsed .navbar-collapse{
  position: absolute;
  width: 100vw;
  left: 0;
  background-color: black;
  z-index: 5;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  padding-top: 25px;
  transition: 0.2 ease-in-out;
}

.collapsed .navbar-toggler {
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 6; 
}

.navbar-collapse.collapsing,
.navbar-collapse.show {
  position: absolute;
  width: 100vw;
  left: 0;
  background-color: black;
  z-index: 5;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  padding-top: 25px;
  transition: 0.2 ease-in-out;
}

@media (max-width: 768px) {
  .collapsed .navbar-collapse {
    position: absolute;
    width: 100vw;
    left: 0;
    background-color: black; 
    z-index: 5;
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    padding-top: 25px
  }
  .collapsed .navbar-toggler {
    position: fixed;
    top: 60px;
    right: 20px;
    z-index: 6; 
  }
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    position: absolute;
    width: 100vw;
    left: 0;
    background-color: black;
    z-index: 5;
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    padding-top: 25px;
    transition: 0.2 ease-in-out;
  }
}


/* banner css */
.banner {
  font-family: 'COD', sans-serif;
  margin-top: 200px;
  height: 50vh;
  width: 100vw;
  background-image: url('./assets/img/BritainandAmerica.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(0.8) saturate(0.5)
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
  white-space: nowrap;
}

.banner h1 {
  font-size: 6vw;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: flex;
  white-space: nowrap;
  color: black;
  margin-top: 38vh;
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
  color: black;
}


/* Projects css */
.project {
  padding: 60px 0;
  background-color: #181818;
}

.project h2 {
  text-align: center;
  font-size: 36px;
  margin-top: 50px;
  margin-bottom: 40px;
  color: ghostwhite;
}

.proj-imgbx {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 80vw;
  max-width: 1200px;
  margin: 0 auto;
}

.proj-imgbx img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.5s ease;
}

.proj-imgbx:hover img {
  opacity: 0;
}

.proj-imgbx::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/img/darrenTillGif.gif') center/cover no-repeat;
  transition: opacity 0.1s linear;
  opacity: 0; 
  z-index: 1;
}

.proj-imgbx:hover::before {
  opacity: 1; 
}

.proj-txtx {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  padding: 20px;
  transition: opacity 1.2s ease;
  opacity: 0;
  z-index: 2;
}

.proj-imgbx:hover .proj-txtx {
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 22px;
  margin-bottom: 10px;
}

.proj-txtx span {
  font-size: 16px;
}

/* css projectdesc */
.projectdesc-content {
  padding: 20px;
  background-color: #f9f9f9;
  margin-top: 20px;
  justify-content: center;
}

.projdesc-content h2 {
  color: #333;
}

.projdesc-content p {
  color: #555;
  line-height: 1.6;
  text-align: left;
}

.projdesc-content .spacer {
  height: 1500px;
  background-color: #e0e0e0;
}

/* Contact.css */
.contact {
  background-color: black;
  padding: 50px 0;
  color: white;
}

.contact h2 {
  text-align: center;
  font-size: 36px;
  margin-top: 50px;
  margin-bottom: 40px;
  color: ghostwhite;
}


.contact form {
  background-color: black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.contact input,
.contact textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2); 
  color: ghostwhite; 
}

.contact input[type="text"],
.contact input[type="email"] {
  height: 40px;
}

.contact textarea {
  resize: none;
}

.contact .px-1 {
  padding-left: 5px;
  padding-right: 5px;
}

.contact button {
  background-color: rgba(255, 255, 255, 0.2); 
  color: rgb(255, 255, 255); 
  border: 1px solid rgba(255, 255, 255, 0.5); 
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.contact button:hover {
  background-color: rgba(248, 248, 255, 0.8);
  color: black;
}

.contact .success {
  color: green;
}

.contact .danger {
  color: red;
}

/* Footer css */

.footer {
  background-color: black; 
  color: white; 
  padding: 20px 0;
  border-top: 20px ghostwhite;
}


.footer a {
  display: inline-block; 
  margin: 0 10px; 
}



/* Model page css */
.ufc-model-page {
  padding: 100px 0;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.ufc-model-page h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.ufc-model-page p {
  font-size: 1.25rem;
  color: #6c757d;
  text-align: center;
}